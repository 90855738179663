.contentRow {
    display: flex
}

.contentRow.contentRow--alignMiddle {
    align-items: center
}

.contentRow.is-deleted {
    opacity: .7
}

.contentRow.is-deleted .contentRow-header,.contentRow.is-deleted .contentRow-title {
    text-decoration: line-through
}

.contentRow-figure {
    vertical-align: top;
    white-space: nowrap;
    word-wrap: normal;
    text-align: center
}

.contentRow-figure img,.contentRow-figure i.fa,.contentRow-figure i.fal,.contentRow-figure i.far,.contentRow-figure i.fas,.contentRow-figure i.fab,.contentRow-figure i.fad,.contentRow-figure .avatar {
    vertical-align: bottom
}

.contentRow-figure.contentRow-figure--fixedBookmarkIcon {
    width: 48px
}

.contentRow-figure.contentRow-figure--fixedBookmarkIcon img,.contentRow-figure.contentRow-figure--fixedBookmarkIcon i.fa,.contentRow-figure.contentRow-figure--fixedBookmarkIcon i.fal,.contentRow-figure.contentRow-figure--fixedBookmarkIcon i.far,.contentRow-figure.contentRow-figure--fixedBookmarkIcon i.fas,.contentRow-figure.contentRow-figure--fixedBookmarkIcon i.fab,.contentRow-figure.contentRow-figure--fixedBookmarkIcon i.fad,.contentRow-figure.contentRow-figure--fixedBookmarkIcon .avatar {
    max-height: 48px
}

.contentRow-figure.contentRow-figure--fixedSmall {
    width: 60px
}

.contentRow-figure.contentRow-figure--fixedSmall img,.contentRow-figure.contentRow-figure--fixedSmall i.fa,.contentRow-figure.contentRow-figure--fixedSmall i.fal,.contentRow-figure.contentRow-figure--fixedSmall i.far,.contentRow-figure.contentRow-figure--fixedSmall i.fas,.contentRow-figure.contentRow-figure--fixedSmall i.fab,.contentRow-figure.contentRow-figure--fixedSmall i.fad,.contentRow-figure.contentRow-figure--fixedSmall .avatar {
    max-height: 60px
}

.contentRow-figure.contentRow-figure--fixedMedium {
    width: 100px
}

.contentRow-figure.contentRow-figure--fixedMedium img,.contentRow-figure.contentRow-figure--fixedMedium i.fa,.contentRow-figure.contentRow-figure--fixedMedium i.fal,.contentRow-figure.contentRow-figure--fixedMedium i.far,.contentRow-figure.contentRow-figure--fixedMedium i.fas,.contentRow-figure.contentRow-figure--fixedMedium i.fab,.contentRow-figure.contentRow-figure--fixedMedium i.fad,.contentRow-figure.contentRow-figure--fixedMedium .avatar {
    max-height: 100px
}

.contentRow-figure.contentRow-figure--fixedMedium.contentRow-figure--fixedMedium--fluidWidth {
    width: auto;
    max-width: 200px
}

.contentRow-figure.contentRow-figure--fixedLarge {
    width: 200px
}

.contentRow-figure.contentRow-figure--fixedLarge img,.contentRow-figure.contentRow-figure--fixedLarge i.fa,.contentRow-figure.contentRow-figure--fixedLarge i.fal,.contentRow-figure.contentRow-figure--fixedLarge i.far,.contentRow-figure.contentRow-figure--fixedLarge i.fas,.contentRow-figure.contentRow-figure--fixedLarge i.fab,.contentRow-figure.contentRow-figure--fixedLarge i.fad,.contentRow-figure.contentRow-figure--fixedLarge .avatar {
    max-height: 200px
}

.contentRow-figure.contentRow-figure--text {
    font-size: 18px
}

.contentRow-figureContainer {
    position: relative
}

.contentRow-figureContainer .contentRow-figureSeparated {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 26px;
    height: 26px;
    font-size: 16px
}

.contentRow-figureIcon {
    text-align: center;
    color: #be003b
}

.contentRow-figureIcon img,.contentRow-figureIcon i.fa,.contentRow-figureIcon i.fal,.contentRow-figureIcon i.far,.contentRow-figureIcon i.fas,.contentRow-figureIcon i.fab,.contentRow-figureIcon i.fad {
    width: 64px;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    border-radius: 4px
}

.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon img,.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon i.fa,.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon i.fal,.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon i.far,.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon i.fas,.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon i.fab,.contentRow-figure--fixedBookmarkIcon .contentRow-figureIcon i.fad {
    width: 48px
}

.contentRow-main {
    flex: 1;
    min-width: 0;
    vertical-align: top;
    padding-left: 10px
}

.contentRow-main:before {
    content: '';
    display: block;
    margin-top: -.18em
}

.contentRow-main.contentRow-main--close {
    padding-left: 6px
}

.contentRow-main:first-child {
    padding-left: 0
}

.contentRow-header {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 15px
}

.contentRow-title {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 15px
}

.contentRow-snippet {
    font-size: 13px;
    margin: .25em 0
}

.contentRow-muted {
    color: #8c8c8c
}

.contentRow-lesser {
    font-size: 13px
}

.contentRow-suffix {
    padding-left: 6px;
    white-space: nowrap;
    word-wrap: normal
}

.contentRow-faderContainer {
    position: relative;
    overflow: hidden
}

.contentRow-faderContent {
    max-height: 150px;
    overflow: hidden
}

.contentRow-fader {
    position: absolute;
    top: 87px;
    left: 0;
    right: 0;
    height: 75px;
    background: transparent;
    background: linear-gradient(to bottom, rgba(254,254,254,0) 0%, #fefefe 80%)
}

.contentRow-minor {
    font-size: 13px;
    color: #8c8c8c
}

.contentRow-minor.contentRow-minor--hideLinks a {
    color: inherit;
    text-decoration: none
}

.contentRow-minor.contentRow-minor--hideLinks a:hover {
    text-decoration: underline
}

.contentRow-minor.contentRow-minor--smaller {
    font-size: 12px
}

.contentRow-minor.contentRow-minor--singleLine {
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis
}

.contentRow-spaced {
    margin: .5em 0
}

.contentRow-spaced:last-child {
    margin-bottom: 0
}

.contentRow-extra {
    float: right;
    padding-left: 6px;
    font-size: 11px
}

.contentRow-extra.contentRow-extra--small {
    font-size: 13px;
    color: #8c8c8c
}

.contentRow-extra.contentRow-extra--normal {
    font-size: 14px;
    color: #8c8c8c
}

.contentRow-extra.contentRow-extra--large {
    font-size: 15px;
    color: #8c8c8c
}

.contentRow-extra.contentRow-extra--larger {
    font-size: 16px;
    color: #8c8c8c
}

.contentRow-extra.contentRow-extra--largest {
    font-size: 18px;
    color: #8c8c8c
}

@media (max-width: 480px) {
    .contentRow-figure.contentRow-figure--fixedBookmarkIcon {
        width:32px
    }

    .contentRow-figure .avatar--s {
        width: 32px;
        height: 32px;
        font-size: 19px
    }

    .contentRow--hideFigureNarrow .contentRow-figure {
        display: none
    }

    .contentRow--hideFigureNarrow .contentRow-main {
        padding-left: 0
    }
}