@import url('./assets/fonts/fonts.css');

@import url('assets/content_row.css');
@import url('assets/bb_code.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --swiper-navigation-color: #D7111B;
    --swiper-pagination-color: #fff;
}

body {
    /*font-family: 'Inter', sans-serif;*/
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.video-side-list:hover::-webkit-scrollbar {
    opacity: 1;
}
.video-side-list::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
    transition: .3s linear;
}
.video-side-list::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}
.video-side-list::-webkit-scrollbar-thumb {
    background: #CED0D4;
    border-radius: 6px;
    -webkit-box-shadow: none;
}

.highlight-section-slide .swiper-pagination {
    position: static;
}

.reaction-icon {
    overflow: hidden;
}

.post-content {
    white-space: normal;
    max-width: 100%;
    text-align: justify;
    font: inherit;
}

.post-content img {
    width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 12px;
}

.post-content img + br {
    display: none;
}

.post-content img+br+img {
    margin-top: 15px;
}

.post-content ul {
    list-style: inside;
}

.is-hidden {
    display: none !important;
}

/* Ensure the editor container takes 100% width */
.editor-container {
    width: 100%;
    max-width: 100%; /* Override any max-width settings */
    box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

.codex-editor + .codex-editor {
    display: none !important;
}

/* Optional: Style the editor blocks */
.ce-block__content {
    max-width: 100%; /* Ensure individual blocks within the editor are also 100% */
}

.break-line.cdx-block {
    padding: 0;
    height: 10px;
}

.ce-toolbar__content {
    max-width: 100%; /* Ensure toolbar content respects the full width */
}

.cd-block__content, .ce-toolbar__content {
    margin: 0 auto; /* Center the content if necessary */
}

.ce-block__content h1 {
    font-size: 18px;
}

.ce-block__content h2 {
    font-size: 24px;
}

.ce-block__content h3 {
    font-size: 30px;
}

.ce-block__content h4 {
    font-size: 36px;
}

.ce-block__content h5 {
    font-size: 42px;
}

.ce-block__content h6 {
    font-size: 48px;
}

.-z-1 {
    z-index: -1;
}

.origin-0 {
    transform-origin: 0;
}

.floating-input-label input:focus ~ label,
.floating-input-label input:not(:placeholder-shown) ~ label,
.floating-input-label textarea:focus ~ label,
.floating-input-label textarea:not(:placeholder-shown) ~ label,
.floating-input-label select:focus ~ label,
.floating-input-label select:not([value='']):valid ~ label {
    /* @apply transform; scale-75; -translate-y-6; */
    --tw-translate-x: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -1.5rem;
}

.floating-input-label input:focus ~ label,
.floating-input-label select:focus ~ label {
    /* @apply text-black; left-0; */
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    left: 0;
}