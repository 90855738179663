.is-expandable:not(.is-expanded) [data-s9e-mediaembed] {
    width: 270px;
    transition: none
}

.bbCodeBlock {
    display: flow-root;
    margin: .5em 0;
    background: #f5f5f5;
    border: 1px solid #e7e7e7;
    border-left: 3px solid #f2930d
}

.bbCodeBlock.bbCodeBlock--quote .bbCodeBlock-content {
    font-size: 13px
}

.bbCodeBlock-title {
    padding: 6px 10px;
    font-size: 13px;
    color: #f2930d;
    background: #fafafa
}

.bbCodeBlock-title:before,.bbCodeBlock-title:after {
    content: " ";
    display: table
}

.bbCodeBlock-title:after {
    clear: both
}

a.bbCodeBlock-sourceJump {
    color: inherit;
    text-decoration: none
}

a.bbCodeBlock-sourceJump:hover {
    text-decoration: underline
}

a.bbCodeBlock-sourceJump:after {
    font-family: 'Font Awesome 5 Pro';
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f0aa";
    width: 1.28571429em;
    text-align: center;
    display: inline-block;
    margin-left: 5px
}

.bbCodeBlock-content {
    position: relative;
    padding: 6px 10px
}

.bbCodeBlock-content:before,.bbCodeBlock-content:after {
    content: " ";
    display: table
}

.bbCodeBlock-content:after {
    clear: both
}

.bbCodeBlock-expandLink {
    display: none;
    position: absolute;
    top: 87px;
    left: 0;
    right: 0;
    height: 75px;
    cursor: pointer;
    z-index: 100;
    background: transparent;
    background: linear-gradient(to bottom, rgba(245,245,245,0) 0%, #f5f5f5 80%)
}

.bbCodeBlock-expandLink a {
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 13px;
    color: #f2930d;
    text-decoration: none
}

.bbCodeBlock--screenLimited .bbCodeBlock-content {
    max-height: 300px;
    max-height: 70vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.bbCodeBlock--expandable {
    overflow: hidden;
    -webkit-transition: -xf-height .25s ease, height .25s ease, padding .25s ease, margin .25s ease, border .25s ease;
    transition: -xf-height .25s ease, height .25s ease, padding .25s ease, margin .25s ease, border .25s ease
}

.bbCodeBlock--expandable .bbCodeBlock-content {
    overflow: hidden
}

.bbCodeBlock--expandable .bbCodeBlock-content .bbCodeBlock:not(.bbCodeBlock--expandable) .bbCodeBlock-content {
    overflow: visible
}

.bbCodeBlock--expandable .bbCodeBlock-content .bbCodeBlock.bbCodeBlock--screenLimited .bbCodeBlock-content {
    overflow: auto
}

.bbCodeBlock--expandable .bbCodeBlock-expandContent {
    max-height: 150px;
    overflow: hidden
}

.bbCodeBlock--expandable.is-expandable .bbCodeBlock-expandLink {
    display: block
}

.bbCodeBlock--expandable.is-expanded .bbCodeBlock-expandContent {
    max-height: none
}

.bbCodeBlock--expandable.is-expanded .bbCodeBlock-expandLink {
    display: none
}

.bbCodeBlock--unfurl {
    color: #141414;
    background: #edf6fd;
    padding: 6px;
    width: 650px;
    max-width: 100%;
    border-left: 2px solid #47a7eb;
    overflow: hidden
}

.bbCodeBlock--unfurl .contentRow-header {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis
}

.bbCodeBlock--unfurl .contentRow-snippet {
    font-size: 12px;
    font-style: normal
}

.bbCodeBlock--unfurl .contentRow-minor {
    font-size: 12px
}

.bbCodeBlock--unfurl .contentRow-minor .js-unfurl-favicon {
    display: inline-block;
}

.bbCodeBlock--unfurl.is-pending {
    opacity: 0.5
}

.bbCodeBlock--unfurl.is-pending.is-recrawl {
    opacity: 1
}

.bbCodeBlock--unfurl.is-simple .contentRow-figure {
    display: none
}

.bbCodeBlock--unfurl .bbCodeBlockUnfurl-icon {
    width: 12px;
    vertical-align: -2px
}

.bbCodeCode {
    margin: 0;
    padding: 0;
    font-family: Monaco,Menlo,Consolas,'Roboto Mono','Andale Mono','Ubuntu Mono',monospace;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    direction: ltr;
    white-space: pre;
    position: relative;
    -moz-tab-size: 4;
    tab-size: 4
}

.has-hiddenscroll .bbCodeCode {
    padding-bottom: 10px
}

.bbCodeCode code {
    font-family: inherit
}

.bbCodeCode .prism-token.prism-comment,.bbCodeCode .prism-token.prism-prolog,.bbCodeCode .prism-token.prism-doctype,.bbCodeCode .prism-token.prism-cdata {
    color: #a50
}

.bbCodeCode .prism-token.prism-constant {
    color: #05a
}

.bbCodeCode .prism-token.prism-tag {
    color: #170
}

.bbCodeCode .prism-token.prism-boolean {
    color: #219
}

.bbCodeCode .prism-token.prism-symbol,.bbCodeCode .prism-token.prism-atrule,.bbCodeCode .prism-token.prism-keyword {
    color: #708
}

.bbCodeCode .prism-token.prism-selector,.bbCodeCode .prism-token.prism-function {
    color: #05a
}

.bbCodeCode .prism-token.prism-deleted {
    color: #d44
}

.bbCodeCode .prism-token.prism-inserted {
    color: #292
}

.bbCodeCode .prism-token.prism-string,.bbCodeCode .prism-token.prism-attr-value {
    color: #a11
}

.bbCodeCode .prism-token.prism-number {
    color: #164
}

.bbCodeCode .prism-token.prism-attr-name,.bbCodeCode .prism-token.prism-char,.bbCodeCode .prism-token.prism-builtin {
    color: #00c
}

.bbCodeCode .prism-token.prism-regex,.bbCodeCode .prism-token.prism-important,.bbCodeCode .prism-token.prism-variable,.bbCodeCode .prism-token.prism-package {
    color: #05a
}

.bbCodeCode .prism-token.prism-class-name,.bbCodeCode .prism-token.prism-important,.bbCodeCode .prism-token.prism-bold {
    color: #00f
}

.bbCodeCode .prism-token.prism-bold {
    font-weight: bold
}

.bbCodeCode .prism-token.prism-italic,.bbCodeCode .prism-token.prism-constant {
    color: #05a;
    font-style: italic
}

.bbCodeCode .prism-token.prism-entity {
    cursor: help
}

.bbCodeInline {
    margin: 0;
    font-size: 80%;
    background: #f5f5f5;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    padding-top: 1px;
    padding-right: 3px;
    padding-bottom: 1px;
    padding-left: 3px;
    font-family: Monaco,Menlo,Consolas,'Roboto Mono','Andale Mono','Ubuntu Mono',monospace;
    line-height: 1.4;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    white-space: pre-wrap
}

.bbCodeSpoiler {
    margin: .5em 0
}

.has-no-js .bbCodeSpoiler-button {
    display: none
}

.bbCodeSpoiler-content {
    display: none;
    opacity: 0;
    -webkit-transition: all .25s ease, -xf-opacity .25s ease;
    transition: all .25s ease, -xf-opacity .25s ease;
    overflow-y: hidden;
    height: 0;
    -webkit-transition-property: all,-xf-height;
    transition-property: all,-xf-height
}

.bbCodeSpoiler-content.is-active {
    display: block
}

.bbCodeSpoiler-content.is-active {
    display: block;
    opacity: 1
}

.bbCodeSpoiler-content.is-transitioning {
    display: block
}

.bbCodeSpoiler-content.is-active {
    height: auto;
    overflow-y: visible
}

.bbCodeSpoiler-content.is-transitioning {
    overflow-y: hidden
}

.has-no-js .bbCodeSpoiler-content {
    display: block !important
}

.bbCodeSpoiler-content>.bbCodeBlock--spoiler {
    margin: .2em 0 0
}

.bbCodeInlineSpoiler {
    filter: blur(6px);
    cursor: pointer
}

.has-js .bbCodeInlineSpoiler a,.has-js .bbCodeInlineSpoiler span {
    pointer-events: none
}

.bbCodeInlineSpoiler .smilie {
    filter: grayscale(90%)
}

.bbCodeInlineSpoiler:hover {
    filter: blur(5px)
}

.has-no-js .lazyload {
    display: none
}